.header {
  background-color: rgba(237, 237, 237, 0.75);
  background-size: cover;
  border-bottom: 1px solid #ccc;
}

.cg-nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
}

.content {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin: auto;
  overflow: auto;
}

.site-title {
  font-weight: 300;
  font-size: 32px;
  float: left;
  margin: 0;
}

.vert-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.site-title a {
  text-decoration: none;
  color: #000;
}

.link {
  display: inline-flex;
  float: right;
}

.link a {
  text-decoration: none;
  color: #000;
  padding: 15px 20px 15px 0;
}

.selected {
  border-bottom: 1px solid #fff;
}

