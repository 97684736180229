.main-section {
  text-align: center;
}

.section {
	margin: 100px 0 100px 0;
}

.title-header {
	text-align: center;
	font-size: 64px;
	margin-top: 100px;
	font-weight: 100;
}

.h2 {
	float: left;

	color: white;
}


.figure {
	margin: 0;
}
.image {
	width: 100%;
}

.hero-copy {
	margin: 50px auto 0 auto;
	width: 80%;
}