body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  padding-top: 50px;
	padding-bottom: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

main {
    position: relative;
    max-width: 2560px;
    margin: 0 auto;
}
p {
  font-weight: 200;
}

h1, h2 {
  font-weight: 100;
}

h2 {
  font-size: 48px;
}

.Header_header__3eIsf {
  background-color: rgba(237, 237, 237, 0.75);
  background-size: cover;
  border-bottom: 1px solid #ccc;
}

.Header_cg-nav__-W-o7 {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
}

.Header_content__bjMF9 {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin: auto;
  overflow: auto;
}

.Header_site-title__FrTN_ {
  font-weight: 300;
  font-size: 32px;
  float: left;
  margin: 0;
}

.Header_vert-center__35Z61 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.Header_site-title__FrTN_ a {
  text-decoration: none;
  color: #000;
}

.Header_link__3fFMx {
  display: inline-flex;
  float: right;
}

.Header_link__3fFMx a {
  text-decoration: none;
  color: #000;
  padding: 15px 20px 15px 0;
}

.Header_selected__1EoxR {
  border-bottom: 1px solid #fff;
}


.Blog_blog__3nDMw {
  text-align: left;
}


.Main_main-section__9WesQ {
  text-align: center;
}

.Main_section__8sZjh {
	margin: 100px 0 100px 0;
}

.Main_title-header__VSl15 {
	text-align: center;
	font-size: 64px;
	margin-top: 100px;
	font-weight: 100;
}

.Main_h2__nlXJf {
	float: left;

	color: white;
}


.Main_figure__14r4n {
	margin: 0;
}
.Main_image__2qIJl {
	width: 100%;
}

.Main_hero-copy__21Mm0 {
	margin: 50px auto 0 auto;
	width: 80%;
}

.DropFade_fader__1Pz9u {
	-webkit-animation: DropFade_fade__GKWSj 5s ease-in-out;
	        animation: DropFade_fade__GKWSj 5s ease-in-out;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards; 
	display: none;
  text-align: center;
}

.DropFade_active__1vDHb {
	display: block;
}

.DropFade_fade-container__2xzIg {
	position: relative;
  text-align: center;
  margin-top: -80px;
  width:100%;
}

.DropFade_first-text__38ql3 {
  display:inline;
}

.DropFade_parent-container__2Vsoj {
  display:flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}


@-webkit-keyframes DropFade_fade__GKWSj{
  0% {color: rgba(0,0,0,0);
  	-webkit-transform: translate(0);
  	        transform: translate(0);
  }
  50% {color: rgba(0,0,0,1);
  	-webkit-transform: translate(0, 50px);
  	        transform: translate(0, 50px);
  }
  100% {color: rgba(0,0,0,0);
  	-webkit-transform: translate(0, 50px);
  	        transform: translate(0, 50px);
  }
}


@keyframes DropFade_fade__GKWSj{
  0% {color: rgba(0,0,0,0);
  	-webkit-transform: translate(0);
  	        transform: translate(0);
  }
  50% {color: rgba(0,0,0,1);
  	-webkit-transform: translate(0, 50px);
  	        transform: translate(0, 50px);
  }
  100% {color: rgba(0,0,0,0);
  	-webkit-transform: translate(0, 50px);
  	        transform: translate(0, 50px);
  }
}
.DC_dc__DA9wb {
  text-align: left;
}


.Modules_blog__2TlYQ {
  text-align: left;
}


